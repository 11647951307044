<template>
  <div class="titleStyle">
    <div :style="{ 'border-bottom': icon ? '4px solid var(--theme)' : ''}" class="titleStyleInside">
      <img v-if="icon" :src="icon()">
      <div class="title">{{ title }}</div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: Function,
      default: null
    },
    title: {
      type: String,
      default: ''
    }

  },
  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .titleStyle{
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    justify-content: space-between;
    .titleStyleInside{
      display: flex;
      align-items: center;
      padding-bottom:16px;

      width: max-content;
      img{
        width: 32px;
        height: 28px;
        margin-right: 12px;
      }
      .title{
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;

      }
    }

  }
</style>
