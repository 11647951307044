<template>
  <div v-loading="loading" class="Content">
    <div v-if="dataInfo.length" class="content">
      <div>
        <div v-for="(item,index) in dataInfo" :key="index" class="content-item">
          <div class="content-text click" @click="$go(`/ConsultingDetail?id=${item.id}&type=${$parent.param.type}`)">
            <div class="circle" />
            <div
              v-for="(item2,index2) in contentData"
              :key="index2"
              :class="item2.class || []"
              :style="item2.style || {}"
              class="colcenter"
            >
              <span :class="classCheck(item,item2)">{{ item2.format ? item2.format(item[item2.id]) : item[item2.id] }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <el-pagination
          v-if="total>0"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="limit"
          class="pagination"
          :current-page="page*1"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <div
      v-else
      style="flex: 1; font-size: 100px; height: 500px"
      class="colCenter it"
    >
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
// import { statusData } from '@/views/personal/page/common.js'
export default {
  props: {
    contentData: {
      type: Array,
      default: () => [

      ]
    },
    methodPath: {
      type: Object,
      default: () => { return {} }
    },
    limit: {
      type: Number,
      default: 4
    },
    detailUrl: {
      type: String,
      default: ''
    },
    initQuery: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      page: 1, // 当前页数
      dataInfo: [],
      total: 0,
      loading: false,
      // statusData,
      routeName: this.$route.path.split('/').slice(-1)[0]
    }
  },
  computed: {
    type() {
      return this.$parent.param.type
    }
  },
  watch: {
    $route() {
      this.getDataInfo()
    }
  },
  created() {
    this.getDataInfo()
  },

  methods: {
    goUrl(id) {
      this.$router.push({ path: `/personal/${this.routeName}/detail`, query: { id, selected: this.$parent.$parent.$refs.left.selected, page: this.page }})
    },
    classCheck(item, item2) {
      if (item2.textClass) {
        return item2.textClass(item[item2.id], this.routeName)
      } else {
        return []
      }
    },
    handleCurrentChange(val) {
      this.$changeParam({ page: val })
    },
    getDataInfo(page, limit = this.limit) {
      this.loading = true
      this.param = this.$getParams()
      page = this.param.page || 1
      this.page = page
      this.$store.dispatch(this.methodPath.search, { page, limit, ...this.initQuery }).then(res => {
        if (res.code === 0) {
          this.total = res.count
          this.dataInfo = res.data
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Content {
  width: 100%;
  overflow: hidden;
  .title {
    border-bottom: 1px solid #e0e0e0;
    height: 41px;
    .text {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-bottom: 4px solid var(--theme);
      height: 41px;
      display: inline-block;
    }
  }

  .pagination {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .colcenter{
    display: flex;
    align-items: center;
  }

}

.content-text{
  display: flex;
  align-items: center;
  padding: 19px 0;
  border-bottom: 1px dotted #EEEEEE;
  .circle{
    width: 4px;
    height: 4px;
    background: var(--theme);
    border-radius: 50%;
    margin-right: 8px;
  }
}

</style>
