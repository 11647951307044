<template>
  <div class="Readily">
    <div class="content">
      <titleStyle style="margin-bottom:12px" :title="param.type" :icon="()=>require(`@/assets/template/${$store.getters.moduleName}/input9icon/${imgList[param.type]}.png`)">
        <div class="fb center click" @click="$go(`/ConsultingCreate?type=${param.type}`)">+ 我要发表</div>
      </titleStyle>
      <contents :init-query="{type:param.type}" :content-data="contentData" :method-path="methodPath" />
    </div>
  </div>
</template>

<script>
import titleStyle from '@/components/titleStyle/index.vue'
import contents from './content.vue'
export default {
  components: { contents, titleStyle },
  data() {
    return {
      contentData: [
        { id: 'title', label: '', style: { color: '#333333', fontSize: '18px', 'font-family': 'Microsoft YaHei', 'font-weight': '400', flex: '1', 'margin-right': '112px' }, class: ['oneLine'] },
        { id: 'createTime', format: (val) => val.split(' ')[0], label: '', style: { color: '#999999', fontSize: '18px', 'font-family': 'Microsoft YaHei', 'font-weight': '400', width: 'max-content' }}
      ],
      methodPath: { // 接口必传
        search: 'home/ConsultingGetConsultList' // 查找接口
      },
      imgList: {
        '在线投诉': '2',
        '在线咨询': '4',
        '对话书记': '6',
        '监督举报': '8',
        '信息监管': '9'
      },
      param: {}

    }
  },
  watch: {
    $route() {
      this.getInit()
    }
  },

  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      this.param = this.$getParams()
    }
  }
}
</script>

<style lang="scss" scoped>
    .Readily{
    background: #f5f9fa;
    flex: 1;
    .content{
      display: flex;
      flex-direction: column;
      width: 1300px;
      background: #fff;
      margin: 0px auto 118px auto;
      padding: 30px 32px 87px 32px;
      position: relative;
      top: 20px;
      .fb{
        width: 114px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid var(--theme);
        border-radius: 4px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--theme);
      }
    }
  }
</style>
